import React, { useEffect, useState } from 'react';
import axios from "axios";
import DesktopWindowsIcon from '../Resources/WindowsIcon.png';
import { Box, Button, Typography, Stack, CircularProgress, Container } from '@mui/material';
import ScreenShotOne from '../Resources/ScreenShot1.png';
import ScreenShotTwo from '../Resources/ScreenShot2.png';
import ScreenShotThree from '../Resources/ScreenShot3.png';

const GetContent = (version) => {

    if (version === null)
        return <CircularProgress />

    return (
        <Stack alignItems="center">
            <Typography textAlign="center" component="h6" variant="h6">
                {"Version: " + version}
            </Typography>

            {DownloadButton()}

            <a href='https://play.google.com/store/apps/details?id=com.zenchovey.warorgan&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img width="256" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
            </a>

            <Container sx={{ mb: 6, display: 'flex', flexDirection: 'column', alignItems: 'center' }} maxWidth="lg">

                <Typography textAlign="center" component="h6" variant="subtitle2">
                    Please be aware that this software is provided for free, without any advertisements. It is a hobby project, and as such, the Windows desktop version is unsigned. Consequently, during installation, you may receive warnings due to the absence of certificates. This is because there is no income generated from the software to cover the cost of obtaining such certificates.
                </Typography>

                <Box component="img" src={ScreenShotOne} sx={{ width: '100%', m: 2 }} />
                <Box component="img" src={ScreenShotTwo} sx={{ width: '100%', m: 2 }} />
                <Box component="img" src={ScreenShotThree} sx={{ width: '100%', m: 2 }} />
            </Container>

        </Stack>
    );
};

const DownloadButton = () => {

    const handleDownload = async () =>
    {
        // Define the path to the file you want to download
        const filePath = '/War Organ.msi';

        // Create a anchor element
        const link = document.createElement('a');
        link.href = filePath;
        link.download = 'War Organ.msi';

        // Dispatch a click event on the anchor element to trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Remove the anchor element

        await axios.post('api/downloads/count');
    };

    return (
        <Button onClick={handleDownload} variant="contained" sx={{ mt:1, width: "222px" }}
            startIcon={<Box component="img" src={DesktopWindowsIcon} sx={{ width: "48px" }} />}>
            <Box>
                <Typography textAlign="left">Download</Typography>
                <Typography textAlign="left">For Windows</Typography>
            </Box>
        </Button>
    );
};

export default function Home(params)
{
    const [version, setVersion] = useState(null);

    function fetchDownloadVersion() {
        axios.get('api/downloads/version').then(result => {
            setVersion(result.data.version);
        });
    }

    useEffect(() => fetchDownloadVersion(), []);

    return GetContent(version);

        //{ GetContent(version) };
        //<Box display="flex" >
        //    <Container maxWidth="md" sx={{ position: "absolute", top:"10%" }}>
        //        <Stack justifyContent="center" alignItems="center">

        //            {GetContent(version)}

        //            <Typography sx={{ mt: 4 }}>
        //                Feedback and issues - warorgan@gmail.com
        //            </Typography>

        //            <Button component={Link} to="/privacy-policy" sx={{ color: 'white', display: 'block' }}>
        //                <Typography variant="caption">Privacy Policy</Typography>
        //            </Button>

        //        </Stack>
        //    </Container>

           

        //</Box>

    //);
}
